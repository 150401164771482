import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import logo from '../assets/logo-removebg.png';
import welcomeImage from '../assets/tectonic3.gif'; // Add the path to your welcome image here


const HeroContainer = styled.div`
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  // height: 100vh; /* Full viewport height */
  // height: calc(100vh - 30px); /* Adjust for the height of the header */
  background-color: ${({ theme }) => theme.colors.backgroud};
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 20px;
  // padding-bottom: 100px;
  height:100vh;
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // padding-top: 100px;
  
  @media (max-width: 768px) { /* when viewed on mobileS */
  flex-direction: column;
  justify-content: space-around;

     }
`;

const HeroText = styled.div`
  margin-bottom: 20px;
`;

const HeroTitle = styled.h1`
  font-size: 3em;
`;

const HeroSubtitle = styled.h3`
  font-size: 1.5em;
`;

const HeroBlink = styled.div`
  color: #ff5722; 
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 18px;
  animation: blink-animation 1.8s linear infinite;
  @keyframes blink-animation {
  0%, 50%, 100% { opacity: 1; }
  25%, 75% { opacity: 0; }
}

`;

const HeroButton = styled.button`
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.secondary};
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.primary};
    transform: scale(1.08);
    };
    
`;

const LogoImage = styled.img`
  max-width: 100%;
  height: auto;
  // padding-top: 50px;

`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const WelcomeImageContainer = styled.div`
  width: 100%;
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const WelcomeImage = styled.img`
  width: 100%;
  height: auto;
`;

const WelcomeTextContainer = styled.div`
  width: 100%;
  max-width: 50%;
  padding: 20px;
  font-size: 1.2em;
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0;
  }
`;

const CountdownContainer = styled.div`
  text-align: center;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  margin: 20px 0;
  border-top: 5px solid ${({ theme }) => theme.colors.primary}; /* Top border */
  border-bottom: 5px solid ${({ theme }) => theme.colors.primary}; /* Bottom border */
  border-radius: 5px;
  font-family: 'Maven Pro', sans-serif; /* Apply digital-style font */
`;

const CountdownText = styled.h2`
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 1.5em;
  letter-spacing: 0.1em;
`;

const CountdownTimer = styled.div`
  font-size: 2.5em;
  font-family: 'Maven Pro', sans-serif; /* Apply digital-style font */
  letter-spacing: 0.1em;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 30px; /* Space between time parts */
   
  @media (max-width: 768px) {/*on mobile views */
    max-width: 100%;
    font-size: 1.5em;
    gap:20px;
  }
`;

const CountdownLabels = styled.div`
  font-size: 1em;
  display: flex;
  justify-content: center;
  gap: 50px; /* Space between labels, matching the time parts */
  text-transform: uppercase;
  @media (max-width: 768px) {/*on mobile views */
    max-width: 100%;
    font-size: 1.0em;
    gap:15px;
  }
`;

const CountdownLabelItem = styled.span`
  text-align: center;
  font-weight: bold;
`;


const AwardsContainer = styled.div`
  padding: 20px;
`;

const AwardsTitle = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`;

const CategoryContainer = styled.div`
  margin-bottom: 20px;
`;

const CategoryTitle = styled.h3`
  font-size: 1.5em;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.primary};
`;

const AwardList = styled.ul`
  list-style: none;
  padding: 0;
`;

const AwardItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const AwardDescription = styled.p`
  margin: 10px 0;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 5px;
`;

const awardsData = {
  Individuals: [
    "Cybersecurity Woman of the Year",
    "Cybersecurity Ethical Hacker of the Year",
    "Cybersecurity Auditor of the Year",
    "Cybersecurity SOC of the year",
    "Cybersecurity GRC of the year",
    "Cybersecurity Personality of the Year",
    "Cybersecurity Rising Star of the Year",
    "Cybersecurity Influencer of the Year",
    "Cybersecurity Entrepreneur of the Year",
    "Cybersecurity Consultant of the Year",
    "CISO of the year",
    "Cybersecurity Researcher of the Year",
    "Cybersecurity Innovator of the Year"
  ],
  Business: [
    "Cybersecurity Start-up of the year",
    "Cybersecurity Service Provider of the Year",
    "Cybersecurity Vendor of the Year"
  ],
  Industry: [
    "Cybersecurity Leader in Banking",
    "Cybersecurity Leader in Telecom",
    "Cybersecurity Leader in the Government and Public sector",
    "Cybersecurity Leader in Healthcare",
    "Cybersecurity Leader in Education"
  ],
  EducationAndLearning: [
    "Cybersecurity School of the year",
    "Cybersecurity University of the year",
    "Cybersecurity Student of the year",
    "Cybersecurity Instructor of the Year",
    "Cybersecurity Lecturer of the Year"
  ],
  Community: [
    "Cybersecurity Citizen of the Year",
    "Cybersecurity Police of the Year"
  ],
  PrestigeAward: [
    "Cybersecurity Prestige award of the year",
    "Cybersecurity Lifetime Achievement Award"
  ]
};

const Home = () => {
  const [expandedAward, setExpandedAward] = useState(null);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  function calculateTimeLeft() {
    const targetDate = new Date('DECEMBER 02, 2024 00:00:00').getTime();
    const now = new Date().getTime();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  const toggleAward = (award) => {
    setExpandedAward(expandedAward === award ? null : award);
  };
  // const handleClick = () => {
  //   window.open("https://forms.gle/zsoUgbhDAJJx68gn8", "_blank")
  // }
  
  return(
  <>
  <HeroContainer>
    <HeroContent>
    <LogoImage src={logo} alt="Africa Cybersecurity Awards Logo" />
      <HeroText>
        <HeroTitle>Africa Cybersecurity Awards (ACA)</HeroTitle>
        <HeroSubtitle>Honoring Innovation and Excellence in Cybersecurity Across Africa</HeroSubtitle>
      </HeroText>
      {/* <HeroBlink>Nominations are Open!</HeroBlink> */}
      {/* <HeroButton onClick={handleClick}>Celebrate Excellence, Nominate Now!</HeroButton> */}
    </HeroContent>
    
  </HeroContainer>

  <WelcomeContainer>
      <WelcomeImageContainer>
        <WelcomeImage src={welcomeImage} alt="Welcome" />
      </WelcomeImageContainer>
      <WelcomeTextContainer>
        <h2>Welcome to the inaugural Africa Cybersecurity Awards</h2>
        <p>
        The first of their kind on the continent, dedicated exclusively to recognizing excellence in cybersecurity. Here, we celebrate and honor the exceptional individuals, teams, and organizations who are at the forefront of securing Africa's digital future. As our continent continues to embrace the transformative power of technology, the need for robust cybersecurity has never been more critical. These awards shine a spotlight on the innovators, protectors, and pioneers leading the charge in safeguarding our digital landscape. Join us as we recognize the champions of cybersecurity who are not only defending but also empowering Africa's digital growth and resilience.
        </p>
      </WelcomeTextContainer>
    </WelcomeContainer>
     {/* Countdown Section */}
     <CountdownContainer>
      <CountdownText>NOMINATIONS HAVE ENDED,AND THE WINNERS WILL BE ANNOUNCED SOON.</CountdownText>
        {/* <CountdownTimer>
          <span>{timeLeft.days}</span>:<span>{timeLeft.hours}</span>:<span>{timeLeft.minutes}</span>:<span>{timeLeft.seconds}</span>
        </CountdownTimer>
    <CountdownLabels>
      <CountdownLabelItem>Day(s)</CountdownLabelItem>
      <CountdownLabelItem>Hours</CountdownLabelItem>
      <CountdownLabelItem>Minutes</CountdownLabelItem>
      <CountdownLabelItem>Seconds</CountdownLabelItem>
    </CountdownLabels> */}
  </CountdownContainer>

    <AwardsContainer>
        <AwardsTitle>Categories</AwardsTitle>
        {Object.keys(awardsData).map((category) => (
          <CategoryContainer key={category}>
            <CategoryTitle>{category.replace(/([A-Z])/g, ' $1').trim()}</CategoryTitle>
            <AwardList>
              {awardsData[category].map((award, index) => (
                <div key={index}>
                  <AwardItem onClick={() => toggleAward(award)}>
                    {award}
                    <span>{expandedAward === award ? '-' : '+'}</span>
                  </AwardItem>
                  {expandedAward === award && (
                    <AwardDescription>
                      The {award} ......
                    </AwardDescription>
                  )}
                </div>
              ))}
            </AwardList>
          </CategoryContainer>
        ))}
      </AwardsContainer>
    


  </>
  )
};

export default Home;
